<style lang="less" scoped></style>

<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        用户管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix">
      <el-table :data="tableData" class="list-table">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="100"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + pager.size * (pager.page - 1) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="名称" min-width="100" sortable />

        <el-table-column prop="remarks" label="描述" show-overflow-tooltip />
      </el-table>

      <el-pagination
        style="margin-top: 15px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pager.page"
        :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
        class="pull-right"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
  import { getAllPermissions } from "@/services/role";
  import { PAGE_SIZE } from "@/config";

  export default {
    data() {
      return {
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        pmsList: [],
        tableData: [],
        filters: {
          name: "",
        },
      };
    },
    methods: {
      /**
       * 获取所以的权限
       */
      async getPermissions() {
        const permData = await getAllPermissions();
        if (permData.errorCode === 0) {
          this.pmsList = permData.data || [];
          this.total = this.pmsList.length;
          this.pagination();
        }
      },
      /**
       * 获取分页数据
       */
      pagination() {
        // console.log(this.pmsList)
        let start = (this.pager.page - 1) * this.pager.size;
        let end = start + this.pager.size;
        this.tableData = this.pmsList.slice(start, end);
        console.log(this.tableData);
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.pagination();
      },
      /**
       *  翻页
       */
      handleCurrentChange(page) {
        this.pager.page = page;
        this.pagination();
      },
    },

    created() {
      this.getPermissions();
    },
  };
</script>
